import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { store } from './common/ReduxStore'
import './App.css'
import { Login } from './domains/auth/Login'
import { Shell } from './Shell'
import ptBr from 'antd/es/locale/pt_BR'
import ConfigProvider from 'antd/es/config-provider'
import { Helmet } from 'react-helmet'
import { getCurrentSite } from './common/getCurrentSite'
import { ClearCacheProvider, useClearCacheCtx } from 'react-clear-cache'

const App: React.FC = () => {
  const site = getCurrentSite()
  return (
    <>
      <ClearCacheProvider duration={15000}>
        <CheckForUpdates />
      </ClearCacheProvider>
      <ConfigProvider locale={ptBr}>
        <Provider store={store}>
          <Router>
            <Switch>
              <Route path="/login" exact component={Login} />
              <Route path="/" component={Shell} />
            </Switch>
          </Router>
        </Provider>
      </ConfigProvider>
      {site === 'EMPLACAFACIL' ? <HeadEmplacafacil /> : null}
      {site === 'EMPLACAR_GOIAS' ? <HeadEmplacarGoias /> : null}
    </>
  )
}

const CheckForUpdates = () => {
  const { isLatestVersion, emptyCacheStorage, loading } = useClearCacheCtx()

  useEffect(() => {
    if (loading || isLatestVersion) return
    void emptyCacheStorage()
    window.location.reload()
  }, [isLatestVersion])

  return null
}

function HeadEmplacafacil() {
  return (
    <Helmet>
      <title>Emplaca Fácil</title>
      <script
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=c86dfa4e-742b-40f7-b095-031822b10292"
      />
      <script async src="https://www.googletagmanager.com/gtag/js?id=UA-151570905-1" />
      <link rel="icon" type="image/png" href="favicon-emplacar-goias.png" />
      <script>
        {`window.dataLayer = window.dataLayer || [];
        function gtag() {dataLayer.push(arguments); }
        gtag('js', new Date());

        gtag('config', 'UA-151570905-1');`}
      </script>
    </Helmet>
  )
}

function HeadEmplacarGoias() {
  return (
    <Helmet>
      <title>Emplacar Goiás</title>
      <script
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=c86dfa4e-742b-40f7-b095-031822b10292"
      />
      <script async src="https://www.googletagmanager.com/gtag/js?id=UA-151570905-2" />
      <link rel="shortcut icon" href="./favicon-emplacar-goias.png" />
      <script>
        {` window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'UA-151570905-2');`}
      </script>
    </Helmet>
  )
}

export default App
